import React, { useEffect } from 'react'
import { useNonInitialEffect } from 'hooks/utils'
import { applyCpfCnpjMask } from 'helpers/masks'
import {
  formatPixKeyForItau,
  getPixKeyType,
  normalize,
  validate,
} from 'helpers/pix'
import useGetSummaryStoreDestinationAccounts, {
  ISummaryStoreDestinationAccount,
  TStatusAccountUnderReview,
} from 'hooks/useGetSummaryStoreDestinationAccounts'
import * as s from '../../styles'
import { useTheme } from 'styled-components'
import { isNotEmpty, isValidCpf } from 'helpers/validators'
import {
  baseURLRegistrationV2DestinationAccounts,
  get,
  patch,
  post,
} from 'services/api'
import { showToast } from 'components/Toast'
import { baseURL } from 'services/apiConfig'
import InfoBox from '../components/InfoBox'

interface SummaryStoreDestinationAccountWithId
  extends ISummaryStoreDestinationAccount {
  id?: number
}

type pixType = 'cpf' | 'cnpj' | 'phone' | 'email' | 'random_key'

interface PayloadPatchProps {
  pix_dict_key: string
  pix_dict_key_type: pixType
}

interface PayloadPostProps {
  cpf_cnpj: string
  pix_dict_key: string
  pix_dict_key_type: pixType
  name: string
  entity_type: 'store'
  store_id: string
}

const AnyBankItauDestinationAccountForm: React.FC<{
  pixFormState: [any, React.Dispatch<React.SetStateAction<any>>]
  nextStep: () => void
  previousStep: () => void
  isLoadingState?: [boolean, React.Dispatch<React.SetStateAction<boolean>>]
  setAllowedToGoToNextStep: React.Dispatch<React.SetStateAction<boolean>>
  callRepresentantiveValidationState?: [
    any,
    React.Dispatch<React.SetStateAction<any>>
  ]
}> = ({
  pixFormState,
  nextStep,
  previousStep,
  isLoadingState,
  callRepresentantiveValidationState,
  setAllowedToGoToNextStep,
}) => {
  const [CNPJOptions, setCNPJOptions] = React.useState([])
  const [getCNPJStatusIsLoading, setGetCNPJStatusIsLoading] =
    React.useState(false)
  const { summary, isLoading, hasError, getSummary } =
    useGetSummaryStoreDestinationAccounts()
  const [form, setForm] = pixFormState
  const [initialPixDictKey, setInitialPixDictKey] = React.useState<string>('')
  const [isLoadingAnyBank, setIsLoadingAnyBank] = isLoadingState
  const [collapseCNPJInfo, setCollapseCNPJInfo] = React.useState<string>('')
  const [collapseBankAccountInfo, setCollapseBankAccountInfo] =
    React.useState<string>('')

  const [selectedSummaryData, setSelectedSummaryData] =
    React.useState<SummaryStoreDestinationAccountWithId>({
      destination_account_uuid: '',
      status: undefined,
      pix_dict_key: '',
      store_cnpj_cpf: '',
      store_uuid: '',
      id: -1,
    })

  const [destinationAccount, setDestinationAccount] = React.useState({
    document_number: '',
    pix_dict_key: '',
  })

  const [destinationAccountFormErrors, setDestinationAccountFormError] =
    React.useState({
      document_number: '',
      pix_dict_key: '',
    })

  const [
    callDestinationAccountValidation,
    setCallDestinationAccountValidation,
  ] = callRepresentantiveValidationState

  const [infoError, setInfoError] = React.useState({
    title: '',
    message: <></>,
  })

  const theme = useTheme()

  useEffect(() => {
    getSummary()
  }, [])

  useEffect(() => {
    setInitialPixDictKey(selectedSummaryData.pix_dict_key)
    if (selectedSummaryData.pix_dict_key) {
      setDestinationAccount({
        ...destinationAccount,
        pix_dict_key: selectedSummaryData.pix_dict_key,
      })
      setDestinationAccountFormError({
        ...destinationAccountFormErrors,
        pix_dict_key: '',
      })
    }
  }, [selectedSummaryData.id])

  React.useEffect(() => {
    setAllowedToGoToNextStep(
      collapseCNPJInfo === 'true' &&
        collapseBankAccountInfo === 'true' &&
        infoError.title === ''
    )
  }, [collapseCNPJInfo, collapseBankAccountInfo, infoError])

  const destinationAccountValidation = async () => {
    const postPayload: PayloadPostProps = {
      cpf_cnpj: normalize(selectedSummaryData.store_cnpj_cpf),
      pix_dict_key: normalize(destinationAccount.pix_dict_key), //limpar chave pix
      pix_dict_key_type: getPixKeyType(destinationAccount.pix_dict_key),
      name: `destination_acc_${normalize(selectedSummaryData.store_cnpj_cpf)}`,
      entity_type: 'store',
      store_id: selectedSummaryData.store_uuid,
    }
    if (
      validate(destinationAccount.pix_dict_key).length === 0 &&
      destinationAccount?.pix_dict_key
    ) {
      setDestinationAccountFormError({
        ...destinationAccountFormErrors,
        pix_dict_key: 'Chave Pix inválida',
      })
      return
    }

    const patchPayload: PayloadPatchProps = {
      pix_dict_key: postPayload.pix_dict_key,
      pix_dict_key_type: postPayload.pix_dict_key_type,
    }

    if (
      postPayload?.pix_dict_key &&
      !isThereAnAccountUnderReview(selectedSummaryData?.status) &&
      normalize(initialPixDictKey) ===
        normalize(destinationAccount.pix_dict_key)
    ) {
      setForm({
        ...form,
        destination_account: {
          ...patchPayload,
        },
        company: {
          ...form.company,
          document_number: selectedSummaryData.store_cnpj_cpf,
        },

        legal_representative: {
          ...form.legal_representative,
          document_number: destinationAccount.document_number,
        },
      })
      nextStep()
    }

    if (
      !selectedSummaryData?.destination_account_uuid &&
      postPayload?.store_id &&
      postPayload?.pix_dict_key
    ) {
      handlePost(postPayload)
    }

    if (
      selectedSummaryData.destination_account_uuid &&
      selectedSummaryData.store_uuid &&
      normalize(initialPixDictKey) != normalize(destinationAccount.pix_dict_key)
    ) {
      handlePatch(patchPayload)
    }
  }

  const handlePost = async (payload: PayloadPostProps) => {
    setIsLoadingAnyBank(true)
    await post(`${baseURLRegistrationV2DestinationAccounts}`, payload)
      .then((res) => {
        setIsLoadingAnyBank(false)
        setForm({
          ...form,
          destination_account: {
            pix_dict_key: payload.pix_dict_key,
            pix_dict_key_type: payload.pix_dict_key_type,
          },
          company: {
            ...form.company,
            document_number: selectedSummaryData.store_cnpj_cpf,
          },

          legal_representative: {
            ...form.legal_representative,
            document_number: destinationAccount.document_number,
          },
        })
        nextStep()
      })
      .catch((e) => {
        if (window['env']['name'] !== 'production') {
          setForm({
            ...form,
            destination_account: {
              pix_dict_key: payload.pix_dict_key,
              pix_dict_key_type: payload.pix_dict_key_type,
            },
            company: {
              ...form.company,
              document_number: selectedSummaryData.store_cnpj_cpf,
            },

            legal_representative: {
              ...form.legal_representative,
              document_number: destinationAccount.document_number,
            },
          })
          setIsLoadingAnyBank(false)
          nextStep()
        }
        setIsLoadingAnyBank(false)
        showToast({
          type: 'error',
          message: JSON.parse(e.request.response).detail,
        })
      })
  }

  const handlePatch = async (payload: PayloadPatchProps) => {
    setIsLoadingAnyBank(true)
    await patch(
      `${baseURLRegistrationV2DestinationAccounts}/${selectedSummaryData.destination_account_uuid}`,
      payload
    )
      .then((res) => {
        showToast({
          type: 'success',
          message: 'Chave Pix alterada com sucesso!',
        })
        setSelectedSummaryData({
          ...selectedSummaryData,
          pix_dict_key: res.data.pix_dict_key,
        })
        setForm({
          ...form,
          destination_account: {
            pix_dict_key: payload.pix_dict_key,
            pix_dict_key_type: payload.pix_dict_key_type,
          },
          company: {
            ...form.company,
            document_number: selectedSummaryData.store_cnpj_cpf,
          },

          legal_representative: {
            ...form.legal_representative,
            document_number: destinationAccount.document_number,
          },
        })
        setIsLoadingAnyBank(false)
        nextStep()
      })
      .catch((e) => {
        if (window['env']['name'] !== 'production') {
          setIsLoadingAnyBank(false)
          nextStep()
        }
        showToast({
          type: 'error',
          message: JSON.parse(e.request.response).detail,
        })
        setIsLoadingAnyBank(false)
      })
  }

  const handleGetCNPJStatus = async (data): Promise<boolean> => {
    return await get(
      `${baseURL}/v1/pix-any-bank/company/${data.store_cnpj_cpf}`
    )
      .then((data) => {
        if (
          data?.data.natureza_juridica.includes('213-5') &&
          data?.data.simei.optante
        ) {
          setInfoError({
            title: 'A natureza jurídica deste CNPJ não pode ser MEI',
            message: (
              <>
                Não é possível cadastrar uma conta bancária pertencente a uma
                empresa na modalidade de Microempreendedor individual.
              </>
            ),
          })
          return false
        }
        setInfoError({
          title: '',
          message: <></>,
        })
        return true
      })
      .catch((error) => {
        setInfoError({
          title: 'Informações inválidas.',
          message: (
            <>
              Confira se as informações no campo de <u>CNPJ da Conta Destino</u>
              , estão corretas e tente novamente.
            </>
          ),
        })

        return false
      })
      .finally(() => setGetCNPJStatusIsLoading(false))
  }

  useEffect(() => {
    if (summary && summary.count === 0 && summary.total !== -1) {
      setCNPJOptions([
        {
          id: 0,
          text: 'Não existem CNPJs cadastrados',
          onClick: () => {
            setSelectedSummaryData({
              destination_account_uuid: null,
              status: null,
              pix_dict_key: null,
              store_cnpj_cpf: null,
              store_uuid: '',
              id: -1,
            })
          },
        },
      ])
    } else if (summary && summary.total > 0) {
      setCNPJOptions([
        ...summary.data
          .filter(
            (data: ISummaryStoreDestinationAccount) =>
              data.store_cnpj_cpf?.length > 11
          )
          .map((data: ISummaryStoreDestinationAccount, index) => {
            return {
              id: index,
              text: applyCpfCnpjMask(data.store_cnpj_cpf),
              onClick: async () => {
                setGetCNPJStatusIsLoading(true)
                await handleGetCNPJStatus(data)
                if (!data?.pix_dict_key) {
                  setSelectedSummaryData({
                    ...data,
                    id: index,
                    pix_dict_key: '',
                    store_cnpj_cpf: applyCpfCnpjMask(data.store_cnpj_cpf),
                  })
                } else {
                  setInitialPixDictKey(data.pix_dict_key)
                  setSelectedSummaryData({
                    ...data,
                    id: index,
                    pix_dict_key: formatPixKeyForItau(
                      data.pix_dict_key
                    )?.replace('+55', ''),
                    store_cnpj_cpf: applyCpfCnpjMask(data.store_cnpj_cpf),
                  })
                }
              },
            }
          }),
      ])
    }
  }, [summary])

  const isThereAnAccountUnderReview = (
    status: TStatusAccountUnderReview
  ): boolean => {
    if (!status || status === 'rejected') {
      return false
    }
    if (status && status === 'in-progress') {
      return true
    }
    return false
  }

  const isThereAlreadyPixDictKeyVinculatedToCNPJ = (
    status: TStatusAccountUnderReview
  ): boolean => {
    return status === 'finished'
  }

  React.useEffect(() => {
    if (callDestinationAccountValidation) {
      validateFields()
      setCallDestinationAccountValidation(false)
    }
  }, [callDestinationAccountValidation])

  const validateFields = async () => {
    const err = { ...destinationAccountFormErrors }
    for (const [key, value] of Object.entries(destinationAccount)) {
      if (!err[key]) err[key] = isNotEmpty(value) ? '' : 'Campo Obrigatório'
    }
    if (!isValidCpf(destinationAccount.document_number)) {
      err.document_number = 'Campo inválido'
    }
    setDestinationAccountFormError(err)

    const errList = Object.values(err).filter((x) => x !== '')
    if (!errList || errList.length === 0) {
      const legalRepresentativeInfo = {
        legal_representative: {
          document_number: destinationAccount.document_number.replace(
            /[./-]+/g,
            ''
          ),
        },
      }

      const destinationAccountInfo = {
        destination_account: {
          pix_dict_key: destinationAccount.pix_dict_key,
        },
      }

      setForm({
        ...form,
        ...legalRepresentativeInfo,
        ...destinationAccountInfo,
      })
      destinationAccountValidation()
    }
  }

  return (
    <>
      <s.Sections>
        <s.SectionsDestinationAccountFormWrapper>
          {(selectedSummaryData?.pix_dict_key &&
            isThereAlreadyPixDictKeyVinculatedToCNPJ(
              selectedSummaryData?.status
            )) ||
          (selectedSummaryData?.pix_dict_key &&
            isThereAnAccountUnderReview(selectedSummaryData?.status)) ? (
            <s.AnyBankItauInputWrapper>
              <s.CollapseTextRender
                isOpen={
                  selectedSummaryData?.pix_dict_key &&
                  isThereAlreadyPixDictKeyVinculatedToCNPJ(
                    selectedSummaryData?.status
                  )
                }
              >
                <s.Text
                  fontWeight={600}
                  margin="32px 0 0 0"
                  color="cancelledStatusTextColor"
                >
                  Este CNPJ já possui uma chave Pix vinculada, portanto não pode
                  ser vinculada a outra. É possível alterar sua chave nas
                  configurações da conta listada na aba Pix.
                </s.Text>
              </s.CollapseTextRender>
              <s.CollapseTextRender
                isOpen={
                  selectedSummaryData?.pix_dict_key &&
                  isThereAnAccountUnderReview(selectedSummaryData?.status)
                }
              >
                <s.Text
                  fontWeight={600}
                  margin="32px 0 0 0"
                  color="cancelledStatusTextColor"
                >
                  Este CNPJ já possui um processo de abertura de conta em
                  andamento. Aguarde a finalização do processo atual.
                </s.Text>
              </s.CollapseTextRender>
            </s.AnyBankItauInputWrapper>
          ) : (
            <></>
          )}
          <s.Section>
            <InfoBox error={infoError} />
            <s.TitleWrapper margin={infoError.title ? '32px 0 0 0 ' : '0px'}>
              <s.Icon name="bank" fill="gray1" />
              <s.Text type="headline" color="graytheme6">
                Conta bancária de destino
              </s.Text>
            </s.TitleWrapper>
            <s.Text margin="32px 0px 0px 0px" type="paragraph">
              A conta de destino precisa ser de uma Pessoa Jurídica com CNPJ já
              cadastrado em uma loja no Painel Shipay.
            </s.Text>
            <s.Text margin="32px 0px 0px 0px" type="paragraph">
              A natureza jurídica deste CNPJ não pode ser MEI.
            </s.Text>
            <s.AnyBankItauDestinationAccountInputWrapper>
              <s.SectionLineCenter>
                <s.Text fontWeight={600} type="headline" color={'graytheme6'}>
                  CNPJ da Conta Destino
                </s.Text>
                <s.InputSelectDestinationAccount
                  testId="destination-account-cnpj-test"
                  width={260}
                  optionBoxSize={150}
                  optionBoxDirection="top"
                  isLoading={isLoading || getCNPJStatusIsLoading}
                  options={CNPJOptions}
                  selected={{
                    id: selectedSummaryData.id,
                    name: selectedSummaryData.store_cnpj_cpf,
                  }}
                  onSelectedOption={(selectedOption) => {
                    if (selectedOption.id) {
                      if (selectedSummaryData.pix_dict_key) {
                        setDestinationAccountFormError({
                          ...destinationAccountFormErrors,
                          pix_dict_key: '',
                        })
                      }
                    }
                  }}
                />
              </s.SectionLineCenter>
              <s.SectionLine>
                <s.Text
                  color={
                    destinationAccountFormErrors.pix_dict_key
                      ? 'redshipay'
                      : 'graytheme6'
                  }
                  fontWeight={600}
                  type="headline"
                >
                  Chave Pix
                </s.Text>
                <s.InputText
                  testId="destination-account-pix-key-test"
                  width={260}
                  onBlur={() => {
                    if (
                      formatPixKeyForItau(selectedSummaryData.pix_dict_key) !=
                      undefined
                    ) {
                      setSelectedSummaryData({
                        ...selectedSummaryData,
                        pix_dict_key: formatPixKeyForItau(
                          selectedSummaryData.pix_dict_key
                        )!.replace('+55', ''),
                      })
                    }
                  }}
                  disabled={
                    summary?.count === 0 ||
                    isThereAnAccountUnderReview(selectedSummaryData?.status) ||
                    isThereAlreadyPixDictKeyVinculatedToCNPJ(
                      selectedSummaryData?.status
                    )
                  }
                  error={
                    destinationAccountFormErrors.pix_dict_key
                      ? { message: destinationAccountFormErrors.pix_dict_key }
                      : false
                  }
                  suffix={
                    destinationAccountFormErrors.pix_dict_key ? (
                      <s.Icon name="alertcirclefilled" fill="lightred2" />
                    ) : (
                      <></>
                    )
                  }
                  value={destinationAccount.pix_dict_key}
                  onChange={(e) => {
                    setDestinationAccount({
                      ...destinationAccount,
                      pix_dict_key: e.target.value?.replace('+55', ''),
                    })
                    if (destinationAccountFormErrors.pix_dict_key) {
                      setDestinationAccountFormError({
                        ...destinationAccountFormErrors,
                        pix_dict_key: '',
                      })
                    }
                  }}
                />
              </s.SectionLine>
            </s.AnyBankItauDestinationAccountInputWrapper>
          </s.Section>
          <s.Line />
          <s.Section>
            <s.Text fontWeight={600} type="headline" color={'graytheme6'}>
              Este CNPJ é o mesmo da empresa contratante deste serviço?
            </s.Text>
            <s.AnyBankItauBooleanInputWrapper>
              <s.RadioBox
                label="Não"
                id="collapseCNPJInfoNo"
                name="collapseCNPJInfoNo"
                disabled={
                  summary?.count === 0 ||
                  isThereAnAccountUnderReview(selectedSummaryData?.status) ||
                  isThereAlreadyPixDictKeyVinculatedToCNPJ(
                    selectedSummaryData?.status
                  )
                }
                value={collapseCNPJInfo === 'false'}
                checked={collapseCNPJInfo === 'false'}
                handleChange={() => {
                  setCollapseCNPJInfo('false')
                }}
              />
              <s.RadioBox
                label="Sim"
                disabled={
                  summary?.count === 0 ||
                  isThereAnAccountUnderReview(selectedSummaryData?.status) ||
                  isThereAlreadyPixDictKeyVinculatedToCNPJ(
                    selectedSummaryData?.status
                  )
                }
                id="collapseCNPJInfoYes"
                name="collapseCNPJInfoYes"
                value={collapseCNPJInfo === 'true'}
                checked={collapseCNPJInfo === 'true'}
                handleChange={() => {
                  setCollapseCNPJInfo('true')
                }}
              />
            </s.AnyBankItauBooleanInputWrapper>
            <s.CollapseTextRender isOpen={collapseCNPJInfo === 'false'}>
              <s.Text margin="32px 0 0 0">
                Selecione o CNPJ da empresa contratante, é necessário que ele
                esteja cadastrado em uma loja{' '}
                {theme.domain === 'ConexãoItaú'
                  ? 'no Conexão Itaú.'
                  : 'na Shipay.'}
              </s.Text>
            </s.CollapseTextRender>
          </s.Section>
          <s.Line />
          <s.Section>
            <s.Text fontWeight={600} type="headline" color={'graytheme6'}>
              Esta chave Pix está associada à conta bancária de destino?
            </s.Text>
            <s.AnyBankItauBooleanInputWrapper>
              <s.RadioBox
                label="Não"
                id="collapseBankAccountInfoNo"
                name="collapseBankAccountInfoNo"
                disabled={
                  summary?.count === 0 ||
                  isThereAnAccountUnderReview(selectedSummaryData?.status) ||
                  isThereAlreadyPixDictKeyVinculatedToCNPJ(
                    selectedSummaryData?.status
                  )
                }
                value={collapseBankAccountInfo === 'false'}
                checked={collapseBankAccountInfo === 'false'}
                handleChange={() => {
                  setCollapseBankAccountInfo('false')
                }}
              />
              <s.RadioBox
                label="Sim"
                id="collapseBankAccountInfoYes"
                name="collapseBankAccountInfoYes"
                disabled={
                  summary?.count === 0 ||
                  isThereAnAccountUnderReview(selectedSummaryData?.status) ||
                  isThereAlreadyPixDictKeyVinculatedToCNPJ(
                    selectedSummaryData?.status
                  )
                }
                value={collapseBankAccountInfo === 'true'}
                checked={collapseBankAccountInfo === 'true'}
                handleChange={() => {
                  setCollapseBankAccountInfo('true')
                }}
              />
            </s.AnyBankItauBooleanInputWrapper>
            <s.CollapseTextRender isOpen={collapseBankAccountInfo === 'false'}>
              <s.Text margin="32px 0 0 0">
                A conta de destino precisa ter uma chave Pix associada, é
                necessário fazer o cadastro através do internet banking ou de um
                app do banco.
              </s.Text>
            </s.CollapseTextRender>
          </s.Section>
          <s.Line />
          <s.TitleWrapper>
            <s.Icon name="customer" fill="gray1" />
            <s.Text type="headline" color="graytheme6">
              Representante legal
            </s.Text>
          </s.TitleWrapper>
          <s.Section>
            <s.Text margin="0 0 0 0" type="paragraph">
              Esta pessoa deve ter poderes legais para realizar a abertura da
              conta bancária da passagem em nome da empresa.
            </s.Text>
            <s.Text margin="32px 0 0 0" type="paragraph">
              Posteriormente será necessário validar sua identidade por
              reconhecimento facial e análise de documento.
            </s.Text>
            <s.Section>
              <s.SectionLine>
                <s.Text
                  fontWeight={600}
                  type="headline"
                  color={
                    destinationAccountFormErrors.document_number
                      ? 'redshipay'
                      : 'graytheme6'
                  }
                >
                  CPF do representante
                </s.Text>
                <s.InputText
                  testId="legal-representative-CPF-test"
                  width={260}
                  maxLength={14}
                  value={destinationAccount.document_number}
                  onChange={(e) => {
                    setDestinationAccount({
                      ...destinationAccount,
                      document_number: applyCpfCnpjMask(e.target.value),
                    })
                    if (destinationAccountFormErrors.document_number) {
                      setDestinationAccountFormError({
                        ...destinationAccountFormErrors,
                        document_number: '',
                      })
                    }
                  }}
                  error={
                    destinationAccountFormErrors.document_number
                      ? {
                          message: destinationAccountFormErrors.document_number,
                        }
                      : false
                  }
                  suffix={
                    destinationAccountFormErrors.document_number ? (
                      <s.Icon name="alertcirclefilled" fill="lightred2" />
                    ) : (
                      <></>
                    )
                  }
                />
              </s.SectionLine>
            </s.Section>
          </s.Section>
        </s.SectionsDestinationAccountFormWrapper>
      </s.Sections>
    </>
  )
}

export default AnyBankItauDestinationAccountForm
